import React from 'react'
import Header from './Home/Header'
import MainSlider from './Home/MainSlider';
import ServiceSection from './Home/ServiceSection ';
import Categories from './Home/Categories';
import Courses from './Home/Courses.jsx';
import Faq from './Home/Faq.jsx';
import ParallaxText from "./ParallaxText"
import { useRef, useEffect } from "react";
import OurProjects from './Home/OurProjects.jsx';
import Testimonials from './Home/Testimonials.jsx';
import SuccessStories from './Home/SuccessStories.jsx';
import { Helmet } from "react-helmet-async";

function Home() {
 const faqRef = useRef(null);

  useEffect(() => {
  
    faqRef.current = faqRef.current;
  }, []);

  const scrollToFAQ = () => {
    if (faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="page-wraper">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="keywords"
          content="
    coderWing , coding learning, coding classes,
    full stack, react course, developer of python, software development courses,
    online coding training, online course coding, coding education online,
    program developer course, future skill, free online programming courses ,
    free online courses coding , best python lessons , mern stack course , full
    stack developer training free , python courses online free , free online
    course in python programming , python programming free online course ,
    online programming courses , best website development course , web
    development course , full stack developer courses online , website
    development course online , coding courses beginners , web dev online
    courses , python learning course , best online programming courses , best
    python courses online , mern course , python training online free , online
    certification course for python , python online certificate course , best
    online course on python , best coding online course , best full stack dev
    course , learn python programming online free , developer course , full
    stack web development course free with certification , online skill
    development courses , online courses software , react course online , coding
    learning online , learn programing online , best platform to learn python ,
    software development online course , programming courses online for
    beginners, web programming online course, online js course, full stack
    developer free course, basic python course, software developer online
    training, best front end development course, it and programming courses,
    python online course free certification , free online programming
    certification courses , python free online certification course , online
    skills courses , full stack development course free , best full stack web
    development course , online web development , python full stack development
    , online it training , learn software development , python development
    course , best front end developer course , online it training courses , web
    development full stack course , learning full stack development , python
    development training , programming full course , python course training ,
    python for beginners course , full stack development course online , online
    coding certification courses , beginners it course , training for full stack
    developer , training full stack developer , full stack online courses , free
    online coding courses , python web development course , online course
    developers , python full stack development course , best python learning
    platform , mern full stack course , react certificate course , python
    developer full course , python professional , about coding course , full
    stack development python , full stack development course fees , python
    developer online course , coding certificate course , best platform for
    learning coding , about python course , learn a skill for free , free
    certification course for python , training web , best course for full stack
    developer , development courses online , fullstack development course ,
    javascript certificate course , training web developer , python for
    beginners online course , react js best course , online courses for
    programming languages , python basic to advanced course , skills required
    for python developer , software development course for beginners , python
    online training course , learn to be a full stack developer , programming
    certification course , learn full stack , react developer course , online
    coding learning platform , best online javascript courses , python courses
    online with certificate , full stack developer online course free , best
    course for react js , full stack developer free online course , full
    development course , coding best courses , react developer courses , full
    stack developer learning , online developer training , full stack web
    development course for beginners , technical course online , python learning
    courses free , mern stack learning , technology online courses , it
    developer courses , best online course for full stack developer , coding
    python course , react js certification course , python learning platform ,
    best developer courses , best course for front end development , online
    courses tech , react online training , best full stack web development
    course online , best python online course certification , full stack front
    end developer course , learn python certificate ,
    software development courses ,
skill up ,
upskill courses ,
online skill development courses ,
best training institute in indore ,
training code ,
online training in indore ,
Training Institute Indore ,
Education Center Indore ,
Skill Development Courses Indore ,
Professional Training Institute Indore ,
Career Development Indore ,
Technical Training Programs Indore ,
Certification Courses Indore ,
Vocational Training Institute Indore ,
Learning Center Indore ,
Training Workshops Indore ,
Corporate Training Programs Indore ,
Educational Workshops Indore ,
Skill Enhancement Training Indore ,
Training Courses Indore ,
Indore Training Hub ,
Talent Development Indore ,
Business Training Indore ,
Management Training Indore ,
Online Training Institute Indore ,
Industry-Specific Training Indore ,
Soft Skills Training Indore ,
Professional Development Indore ,
Leadership Training Indore ,
Technical Certification Indore ,
Project Management Training Indore ,
Language Training Institute Indore ,
Entrepreneurship Training Indore ,
Educational Seminars Indore ,
Health and Safety Training Indore ,
Digital Marketing Courses  ,
Financial Training Indore ,
HR Training Institute Indore ,
Customer Service Training Indore ,
Data Science Training Indore ,
 Creative Skills Training Indore ,
Technical Skills Workshops Indore ,
Training Programs for Students Indore ,
Skill Training Institute Indore ,
Business Skills Development Indore ,
Industry Training Indore ,
Professional Certification Indore ,
Training and Development Indore ,
Workplace Training Indore ,
Educational Institute Indore,
Corporate Skill Development Indore, 
Advanced Training Courses Indore ,
Indore Learning Institute ,
ML Training Indore ,
ML Courses for Professionals Indore ,
Advanced React Course Indore ,
Machine Learning with Python Indore ,
ML Certification Program Indore ,
Python for Data Science Indore ,
Machine Learning Training Institute Indore ,
Machine Learning Fundamentals Indore ,
Machine Learning Workshop Indore ,
Machine Learning Programs Indore ,
Machine Learning Courses for Beginners Indore ,




Web Development Courses Near Me,
Local Web Development Training,
Web Developer Classes Nearby,
Web Development Bootcamp Near Me,
Web Developer Certification Near My Location,
Web Developer Training Close to Me,
Nearby Web Development Classes,
Web Development Training Near My Area,
Web Development Certification Near Me,
Local Web Developer Bootcamp,
Web Development Courses in My Area,
Web Development Training Academy Near Me,
Web Developer Certification Close to Me,
Best Web Development Courses Near Me,
Find Local Web Developer Training,
Web Development Training Near You,,
Web Developer Bootcamp Near My Area,
Web Development Classes Near My Location,
Top Web Development Training Nearby,
Web Development Certification Near My Area,
Local Web Development Bootcamp,
Web Development Classes Near You,
Nearby Web Development Training Program,
Web Developer Certification Close to My Location,
Local Web Development Courses,
Web Development Training Near Me Online,
Best Local Web Development Certification,
Web Developer Training Near My Location,
Web Development Courses Near You,
Web Developer Certification Near My Area,
Web Development Bootcamp Close to Me,
Nearby Web Developer Training Academy,
Find Nearby Web Development Classes,
Web Development Training Near Me and Online,
Web Developer Courses in My Area,
Local Web Developer Classes,
Best Web Development Training Near You,
Web Development Certification Close to You,
Web Development Classes Near Me Online,
Nearby Web Developer Certification,
Web Developer Bootcamp Near Me,
Top Web Developer Training Nearby,
Local Web Developer Training Academy,
Web Development Training Programs Near Me,
Web Developer Certification Nearby,
Web Development Bootcamp in My Area,
Web Development Certification Near My Location,
Web Developer Training Near My Area,
Python Courses Near Me,
Local Python Training,
Python Programming Classes Nearby,
Python Bootcamp Near Me,
Python Certification Near My Location,
Find Python Training Near You,
Python Developer Course Near Me,
Local Python Programming Certification,
Python Training Close to Me,
Nearby Python Programming Classes,
Python Coding Bootcamp Near Me,
Python Developer Certification Near My Area,
Python Classes in My Area,
Local Python Developer Training,
Python Programming Certification Near You,
MERN Stack Training Near Me,
MERN Stack Courses Near Me,
Local MERN Stack Training,
MERN Stack Bootcamp Near My Location,
MERN Stack Developer Classes Nearby,
MERN Stack Certification Near Me,
Find MERN Stack Training Near You,
MERN Stack Developer Training Close to Me,
Local MERN Stack Programming Courses,
MERN Stack Classes Near My Area,
Nearby MERN Stack Developer Training,
MERN Stack Developer Certification Near You,
MERN Stack Web Development Near Me,
Local MERN Stack Bootcamp,
MERN Stack Training Academy Near Me,
MERN Stack Certification Near My Area,
React.js Training Near Me,
React.js Courses Near Me,
Local React.js Training,
React.js Developer Classes Nearby,
React.js Bootcamp Near My Location,
React.js Certification Near Me,
Find React.js Training Near You,
React.js Developer Course Close to Me,
Local React.js Programming Classes,
React.js Training Near My Area,
Nearby React.js Developer Bootcamp,
React.js Developer Certification Near You,
React.js Web Development Training Near Me,
Local React.js Bootcamp,
React.js Classes Near My Area,
React.js Certification Close to Me,
Combined Keywords (Python, MERN, React.js),
Python, MERN, React.js Training Near Me,
Local Python, MERN, React.js Courses,
Nearby Python, MERN Stack, React.js Training,
Python, MERN Stack, React.js Bootcamp Near Me,
Python, MERN, React.js Certification Near My Area,
Best Website Development Training,
Top Web Developer Courses,
Professional Website Development,
Become a Web Developer,
Expert Web Development Training,
Web Development Certification,
Advanced Website Development,
Web Developer Bootcamp,
Online Web Developer Training,
Website Developer Skills,
Master Website Development,
Web Development for Professionals,
Best Web Development Courses,
Full-Stack Website Development,
Web Developer Training Online,
Cutting-Edge Web Development,
Web Development Career Path,
High-Quality Web Development Training,
Web Development Certification Online,
Website Developer Training Program,
Comprehensive Web Development Training,
Best Online Web Development Courses,
Web Developer Skills Enhancement,
Hands-On Web Development,
Website Developer Career Training,
Leading Web Development Courses,
Professional Web Developer Certification,
Website Development Mastery,
Top Rated Web Development Training,
Become a Full-Stack Web Developer,
Web Developer Education,
Web Development Course with Certification,
Build Websites Professionally,
Expert-Level Web Development Training,
High-Demand Web Development Skills,
Web Development Training for Experts,
Best Web Development Certification,
Web Developer Training for Professionals,
Website Development Bootcamp,
Web Developer Online Courses,
Advanced Web Developer Certification,
Web Development Training Academy,
Master Web Development Techniques,
Web Development Career Training,
Website Developer Training Certification,
Expert Web Developer Training Program,
Top Web Development Classes,
Web Developer Skills Training,
Website Development Online Training,
Certified Web Developer Courses,
Local Web Developer Training,
Website Development Courses Near Me,
Nearby Web Developer Bootcamp,
Find Local Web Development Training,
Website Developer Courses in My Area,
Web Development Training Near Me,
Best Nearby Website Development Classes,
Local Web Developer Certification,
Website Development Training in indore,
Near Me Web Development Training,
Close to Home Web Developer Courses,
Web Development Bootcamp Near You,
Local Website Developer Certification,
In-Person Web Developer Training,
Website Developer Classes Near Me,
Nearby Website Development Certification,
Find Web Development Training Nearby,
Local Web Developer Education,
Near Me Website Developer Bootcamp,
Web Development Courses Close to Me,
Nearby Web Development Academy,
Website Developer Training Near indore,
Top Local Web Development Courses,
Web Development Certification Near Me,
Best Web Developer Training Nearby,
Local Web Developer Training Programs,
Website Development Bootcamp Near indore,
Web Development Classes Near You,
Find Nearby Web Developer Training,
Close to Me Web Development Courses,
Local Web Development Certification Program,
Web Developer Courses Near My Location,
Web Development Training in My Area,
Website Developer Courses Near indore,
Local Website Developer Bootcamp,
Find Local Web Developer Certification,
Web Developer Training Close to Me,
Website Developer Certification Near Me,
Nearby Web Development Training Programs,
Local Web Development Courses,
In-Person Web Development Training Near Me,
Web Developer Classes Close to You,
Website Developer Training Near indore,
Best Local Web Development Certification,
Web Development Training Near My Location,
Nearby Website Development Classes,
Web Developer Certification Near indore,
Local Website Developer Education Program,
Learn to Code Online,
Coding Bootcamp,
Online Coding Classes,
Coding Training for Beginners,
Advanced Coding Courses,
Software Development Training,
Learn Programming Languages,
Online Programming Bootcamp,
Full-Stack Development,
Web Development Training,
Coding Certifications,
Online Code Learning,
Hands-On Coding Projects,
Interactive Coding Lessons,
Beginner Programming Courses,
Coding for Kids,
Coding for Adults,
Career in Tech,
Custom Coding Courses,
Learn to Build Apps,
Coding Skills Development,
Programming Certification Online,
Online Developer Training,
Code Academy,
Learn to Code Fast,
Best Online Coding Courses,
Real-World Coding Projects,
Coding for Professionals,
Coding for Non-Techies,
Online Software Engineering Courses,
Beginner to Advanced Coding,
Career in Programming,
Master Coding Online,
Programming for Career Change,
Learn to Code from Home,
Coding Course with Certification,
Online Programming Degree,
Learn to Code from Scratch,
Best Programming Courses Online,
Coding for Job Seekers,
Master Programming Languages,
Online Software Development Classes,
Coding for the Workplace,
Web App Development Training,
Software Engineering Bootcamp,
Coding for Entrepreneurs,
Career-Oriented Coding Courses,
Online Programming Workshops,
MERN Stack Training Keywords,
MERN Stack Course,
Learn MERN Stack Online,
MERN Stack Bootcamp,
Full-Stack MERN Development,
MongoDB Express React Node.js,
MERN Stack Developer Training,
Advanced MERN Stack Courses,
Online MERN Stack Certification,
MERN Stack Training Online,
Full-Stack JavaScript Training,
Learn MongoDB,
Master MERN Stack Development,
MERN Stack Web Development,
Learn Express.js,
MERN Stack Projects,
MERN Stack Career Path,
Mastering MERN Stack,
Online Full-Stack Training,
Learn Node.js,
Full-Stack Web Developer Course,
MERN Stack for Beginners,
Advanced MERN Stack Techniques,
Build with MERN Stack,
Online JavaScript Bootcamp,
Hands-On MERN Stack Training,
Full-Stack JavaScript Course,
Learn React and Node.js,
Build MERN Stack Apps,
JavaScript and MERN Stack Training,
MERN Stack Development Certification,
MERN Stack Programming,
Build Full-Stack Applications,
Online MERN Bootcamp,
MERN Stack Developer Certification,
Learn Full-Stack JavaScript,
Advanced MERN Stack Development,
Master Full-Stack Development,
MERN Stack Immersive Bootcamp,
Online MERN Development,
MERN Stack for Web Developers,
Full-Stack Development with MERN,
Comprehensive MERN Stack Training,
MERN Stack Workshop,
Build Web Apps with MERN Stack,
Learn to Code with MERN Stack,
Full-Stack JavaScript Certification,
MERN Stack Real-World Projects,
Online MERN Stack Course,
Master MERN Stack Online,

Python Training Keywords,
Python Programming Course,
Learn Python Online,
Python Bootcamp,
Python for Beginners,
Advanced Python Training,
Python Certification Course,
Python for Data Science,
Python Web Development,
Python Programming Certification,
Learn Python from Scratch,
Python Coding Projects,
Python Developer Training,
Python for Machine Learning,
Master Python Programming,
Python Automation Training,
Python Scripting Course,
Python Training Online,
Python for Software Development,
Python Data Analysis,
Python for Finance,
Learn Python Coding,
Python for Web Developers,
Python Programming Bootcamp,
Python Certification Online,
Python for AI and ML,
Learn Python Fast,
Python for Business,
Python for IT Professionals,
Advanced Python Programming,
Python Data Science Bootcamp,
Python Programming Certification Online,
Learn to Code in Python,
Python for Data Engineers,
Python and Django Training,
Python Full-Stack Development,
Online Python Developer Course,
Python for Cybersecurity,
Python for Network Automation,
Python and Flask Training,
Python for Data Visualization,
Online Python Programming Course,
Python for Bioinformatics,
Python and AI Training,
Master Python for Web Development,
Python Scripting Bootcamp,
Python and Machine Learning Online Course,
Python for Game Development,
Python for Data Scientists,
Learn Python for Software Engineering,
React.js Training Keywords,
React.js Training Course,
Learn React.js Online,
React.js Bootcamp,
React.js for Beginners,
Advanced React.js Training,
React.js Certification,
React.js Developer Course,
React.js Web Development,
Master React.js,
React.js Programming Certification,
Online React.js Training,
Learn React and Redux,
React.js Project-Based Learning,,
React.js for Front-End Developers,
React.js Web App Development,
React.js Online Certification,
Advanced React.js Development,
React.js and Node.js Training,
Learn React Hooks,
React.js for JavaScript Developers,
React.js and Next.js Training,
React.js for Web Developers,
React.js with TypeScript,
Learn to Build React Apps,
Online React.js Developer Training,
React.js Immersive Bootcamp,
React.js Full-Stack Training,
React.js Certification Online,
Learn React.js Fast,
React.js for UI Development,
React.js and GraphQL Training,
React.js for Mobile Apps,
Master React.js Online,
Online React.js Bootcamp,
React.js with Firebase,
React.js for Real-World Projects,
React.js Advanced Concepts,
React.js and Redux Online Course,
Learn React.js for Web Development,
React.js Component Library Training,
React.js and Material-UI,
Master React.js and Redux,
React.js with Webpack,
Online React.js Certification Course,
React.js and JavaScript Mastery,
React.js for Progressive Web Apps,
React.js with Node.js and Express,
Build Scalable Apps with React.js,
React.js and Server-Side Rendering,
Learn to Code with React.js,
Best Software Training Programs,
Best Software Development Courses,
Best Online Software ,
Best Software Training for Beginners,
Best Software Training for Professionals,
Best Software Skills Development,
Best Software Certification Training,
Best Software Bootcamps,
Best Hands-On Software Training,
Best Software Training Workshops,
Best Comprehensive Software Courses,
Best Software Training Resources,
Best Software Training Providers,
Best Affordable Software Courses,
Best Interactive Software Training,
Best Career-Focused Software Training,
Best Expert-Led Software Courses,
Best Flexible Software Training Options,
Best Real-World Software Projects,
Best Software Development Specializations,
Join the Best Software Training Community,
Best Software Training Success Stories,
Best Practices in Software Training,
Discover the Best Software Learning Paths,
Best Software Training for Career Growth,
    "
        />

        <title>CoderWing - Software Training - Best In Indore</title>
        <link rel="canonical" href="https://coderwing.com/" />
      </Helmet>
      <Header scrollToFAQ={scrollToFAQ} />
      <MainSlider />
      <div>
        {" "}
        <ParallaxText baseVelocity={2}>
          <div className="scrollTickel">
            <p>
              <i className="fa fa-star starIcon " aria-hidden="true"></i>SKILL
              DEVELOPMENT
            </p>
            <p>
              {" "}
              <i className="fa fa-star starIcon" aria-hidden="true"></i>GROWTH
            </p>
            <p>
              {" "}
              <i className="fa fa-star starIcon" aria-hidden="true"></i>TRAINING
            </p>
            <p>
              {" "}
              <i className="fa fa-star starIcon" aria-hidden="true"></i>MERN
            </p>
            <p>
              {" "}
              <i className="fa fa-star starIcon" aria-hidden="true"></i>REACT
            </p>
            <p>
              {" "}
              <i className="fa fa-star starIcon" aria-hidden="true"></i>PYTHON
            </p>
          </div>
        </ParallaxText>
      </div>
      <Categories />
      <Courses />
      <OurProjects />
      <Testimonials />
      <SuccessStories />
      <div ref={faqRef}>
        <Faq />
      </div>
    </div>
  );
}

export default Home